import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaLinkedinIn, FaGithub, FaApple } from "react-icons/fa";
import { SiSwift, SiNodedotjs, SiReact, SiFirebase } from "react-icons/si";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Full-stack Developer", "Web3 Specialist", "Data Analytics Enthusiast"],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Spencer Goldberg</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        I am a passionate developer with a strong focus on creating innovative and scalable solutions. With experience in both mobile and backend development, I leverage modern technologies to deliver seamless and impactful user experiences. My expertise spans across iOS development, blockchain integration, and data analytics, always aiming to simplify interactions and add meaningful motion to every project.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me on
          </h2>
          <div className="flex gap-4">
            <button className="bannerIcon" onClick={() => window.open('https://github.com/spencergoldberg1', '_blank')} title="GitHub">
              <FaGithub />
            </button>
            <button className="bannerIcon" onClick={() => window.open('https://www.linkedin.com/in/spencermgoldberg', '_blank')} title="LinkedIn">
              <FaLinkedinIn />
            </button>
            <button className="bannerIcon" onClick={() => window.open('https://apps.apple.com/developer/your-profile-id', '_blank')} title="App Store">
              <FaApple />
            </button>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Best Skills In
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon" title="Swift">
              <SiSwift />
            </span>
            <span className="bannerIcon" title="Node.js">
              <SiNodedotjs />
            </span>
            <span className="bannerIcon" title="React">
              <SiReact />
            </span>
            <span className="bannerIcon" title="Firebase">
              <SiFirebase />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner
