import React from "react";
import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe, FaCode } from "react-icons/fa";
import { SiProgress, SiAntdesign, SiDatabricks, SiEthereum } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Features" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="App Development"
          des="Building user-friendly, high-performance mobile applications using Swift and SwiftUI. Developed and deployed multiple apps available on the App Store."
          icon={<AiFillAppstore />}
        />
        <Card
          title="Web Development"
          des="Extensive experience in full-stack web development using React, Node.js, and GraphQL. Developed custom, scalable, and responsive web applications."
          icon={<FaGlobe />}
        />
        <Card
          title="Blockchain/Web3 Development"
          des="Leveraging blockchain technology to create decentralized applications. Developed smart contracts and integrated blockchain with existing platforms."
          icon={<SiEthereum />}
        />
        <Card
          title="Mobile Development"
          des="Expertise in iOS and cross-platform mobile development using React Native. Built robust mobile applications for various industries."
          icon={<FaMobile />}
        />
        <Card
          title="Hosting Websites"
          des="Providing reliable and scalable hosting solutions using cloud services like AWS. Ensuring websites are always up and running efficiently."
          icon={<FaGlobe />}
        />
        <Card
          title="API Development and Integration"
          des="Developing and integrating robust APIs for seamless communication between systems. Ensuring efficient and secure data exchange."
          icon={<FaCode />}
        />
      </div>
    </section>
  );
};

export default Features;
