import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* Job Experience */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2015 - Present</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Lead iOS Developer"
            subTitle="Tech Innovators - (2020 - Present)"
            result="USA"
            des="Developed and maintained several high-profile iOS applications using Swift and SwiftUI. Led a team of junior developers, providing mentorship and guidance."
          />
          <ResumeCard
            title="iOS Developer"
            subTitle="Mobile Solutions Inc. - (2017 - 2020)"
            result="USA"
            des="Worked on multiple client projects, creating innovative and user-friendly mobile applications. Implemented features and fixed bugs to ensure high performance."
          />
          <ResumeCard
            title="Software Engineer"
            subTitle="Tech Start - (2015 - 2017)"
            result="USA"
            des="Developed backend services and APIs using Node.js and Express. Integrated various third-party services and APIs to enhance application functionalities."
          />
        </div>
      </div>
      {/* Trainer Experience */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2010 - 2015</p>
          <h2 className="text-3xl md:text-4xl font-bold">Trainer Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Web Development Instructor"
            subTitle="Code Academy - (2013 - 2015)"
            result="USA"
            des="Taught web development courses to students, covering topics such as HTML, CSS, JavaScript, and React. Created course materials and projects for hands-on learning."
          />
          <ResumeCard
            title="iOS Development Instructor"
            subTitle="Tech Bootcamp - (2012 - 2013)"
            result="USA"
            des="Conducted workshops and training sessions on iOS development. Helped students build their first iOS apps using Swift and Xcode."
          />
          <ResumeCard
            title="Teaching Assistant"
            subTitle="University of Tech - (2010 - 2012)"
            result="USA"
            des="Assisted professors in conducting classes and labs for computer science courses. Provided support to students during office hours and graded assignments."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
