import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Restify.net"
          des="Similar to the popular Postman App, this website allows testing of different HTTP Requests and Methods with further analysis on all status codes and results."
          src={projectOne}
          websiteLink="https://restify.net/"
        />
        <ProjectsCard
          title="HoloTask"
          des="The all-in-one Project Management App that incorporates easy collaboration with teammates, and easily view all upcoming events and meetings to stay on track!"
          src={projectTwo}
          websiteLink="https://holotask.com/"
          appStoreLink="https://testflight.apple.com/join/h5Evyyy2"
        />
        <ProjectsCard
          title="FaceSense"
          des="An AI project in Python that uses an AlexNet CNN to detect different emotions from people's facial expressions."
          src={projectThree}
          githubLink="https://github.com/spencergoldberg1/FaceSense"
        />
        <ProjectsCard
          title="HoloTask Chat"
          des="A seamless chat integration within HoloTask to enable real-time communication and collaboration among team members."
          src={projectThree}
          githubLink="https://github.com/spencergoldberg1/holo-task-chat"
        />
        <ProjectsCard
          title="Project Management Cloud Functions"
          des="Cloud functions to support the backend operations of the Project Management application, ensuring smooth data handling and processing."
          src={projectOne}
          githubLink="https://github.com/spencergoldberg1/ProjectManagementCloudFunctions"
        />
        <ProjectsCard
          title="CryptoStore"
          des="A JavaScript-based application for managing and storing cryptocurrency securely with integrated cloud functions."
          src={projectTwo}
          githubLink="https://github.com/spencergoldberg1/CryptoStore"
        />
      </div>
    </section>
  );
}

export default Projects
