import React from 'react'
import { FaLinkedinIn, FaGithub, FaApple } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Spencer Goldberg</h3>
        <p className="text-lg font-normal text-gray-400">
          Full-Stack Developer
        </p>
        <p className="text-base text-gray-400 tracking-wide">
          I am a passionate developer with a strong focus on creating innovative and scalable solutions. Let's connect!
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+1 216-272-5745</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">spencergoldberg2z@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me on</h2>
        <div className="flex gap-4">
          <button className="bannerIcon" onClick={() => window.open('https://github.com/spencergoldberg1', '_blank')} title="GitHub">
            <FaGithub />
          </button>
          <button className="bannerIcon" onClick={() => window.open('https://www.linkedin.com/in/spencermgoldberg', '_blank')} title="LinkedIn">
            <FaLinkedinIn />
          </button>
          <button className="bannerIcon" onClick={() => window.open('https://apps.apple.com/developer/your-profile-id', '_blank')} title="App Store">
            <FaApple />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft
